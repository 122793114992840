import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import ApiIndex from '@hub-fe/common/apiIndex';

const IndexPage: React.FC = () => {
    const data = useStaticQuery(graphql`
        query {
            siteSearchIndex {
                index
            }
            spec: openApiFile(name: { eq: "hub-site-api-with-samples.json" }) {
                internal {
                    content
                }
            }
        }
    `);
    return <ApiIndex data={data} />;
};

// Needed for Gatsby
// ts-unused-exports:disable-next-line
export default IndexPage;
