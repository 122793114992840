import loadable from '@loadable/component';
import React from 'react';

import LoadingPage from '@hub-fe/common/LoadingPage';
import { siteName } from '@hub-fe/common/siteInfo.variants';
import { DamlHubHelmet, DocsNav } from '@hub-fe/landing/nav';

const RedocStandalone = loadable(() => import('redoc'), {
    resolveComponent: components => components.RedocStandalone,
    fallback: <LoadingPage />,
});

const colors = {
    aqua_10: 'hsl(188, 95%, 15%)',
    aqua_20: 'hsl(188, 95%, 25%)',
    aqua_30: 'hsl(188, 95%, 35%)',
    aqua_40: 'hsl(188, 95%, 45%)',
    aqua_50: 'hsl(188, 95%, 55%)',
    aqua_60: 'hsl(188, 95%, 65%)',
    aqua_70: 'hsl(188, 95%, 75%)',
    aqua_80: 'hsl(188, 95%, 85%)',
    aqua_90: 'hsl(188, 95%, 95%)',
    blue_10: 'hsl(224, 85%, 14%)',
    blue_20: 'hsl(224, 85%, 24%)',
    blue_30: 'hsl(224, 85%, 34%)',
    blue_40: 'hsl(224, 85%, 44%)',
    blue_50: 'hsl(224, 85%, 54%)',
    blue_60: 'hsl(224, 85%, 64%)',
    blue_70: 'hsl(224, 85%, 74%)',
    blue_80: 'hsl(224, 85%, 84%)',
    blue_90: 'hsl(224, 85%, 94%)',
    red_10: 'hsl(2, 74%, 11%)',
    red_20: 'hsl(2, 74%, 21%)',
    red_30: 'hsl(2, 74%, 31%)',
    red_40: 'hsl(2, 74%, 41%)',
    red_50: 'hsl(2, 74%, 51%)',
    red_60: 'hsl(2, 74%, 61%)',
    red_70: 'hsl(2, 74%, 71%)',
    red_80: 'hsl(2, 74%, 81%)',
    red_90: 'hsl(2, 74%, 91%)',
    green_10: 'hsl(146, 90%, 16%)',
    green_20: 'hsl(146, 90%, 26%)',
    green_30: 'hsl(146, 90%, 36%)',
    green_40: 'hsl(146, 90%, 46%)',
    green_50: 'hsl(146, 90%, 56%)',
    green_60: 'hsl(146, 90%, 66%)',
    green_70: 'hsl(146, 90%, 76%)',
    green_80: 'hsl(146, 90%, 86%)',
    green_90: 'hsl(146, 90%, 96%)',
    yellow_10: 'hsl(61, 87%, 14%)',
    yellow_20: 'hsl(61, 87%, 24%)',
    yellow_30: 'hsl(61, 87%, 34%)',
    yellow_40: 'hsl(61, 87%, 44%)',
    yellow_50: 'hsl(61, 87%, 54%)',
    yellow_60: 'hsl(61, 87%, 64%)',
    yellow_70: 'hsl(61, 87%, 74%)',
    yellow_80: 'hsl(61, 87%, 84%)',
    yellow_90: 'hsl(61, 87%, 94%)',
    magenta_10: 'hsl(292, 83%, 10%)',
    magenta_20: 'hsl(292, 83%, 20%)',
    magenta_30: 'hsl(292, 83%, 30%)',
    magenta_40: 'hsl(292, 83%, 40%)',
    magenta_50: 'hsl(292, 83%, 50%)',
    magenta_60: 'hsl(292, 83%, 60%)',
    magenta_70: 'hsl(292, 83%, 70%)',
    magenta_80: 'hsl(292, 83%, 80%)',
    magenta_90: 'hsl(292, 83%, 90%)',
    magenta_100: 'hsl(292, 83%, 100%)',
    grey_10: 'hsl(223, 20%, 15%)',
    grey_20: 'hsl(223, 20%, 25%)',
    grey_30: 'hsl(223, 20%, 35%)',
    grey_40: 'hsl(223, 20%, 45%)',
    grey_50: 'hsl(223, 20%, 55%)',
    grey_60: 'hsl(223, 20%, 65%)',
    grey_70: 'hsl(223, 20%, 75%)',
    grey_80: 'hsl(223, 20%, 85%)',
    grey_90: 'hsl(223, 20%, 95%)',
    blue_00: '#fafbff',
    grey_00: '#fafafa',
    grey_95: '#f7f7f8',
};

const text_color = colors.grey_10;

const ApiIndex: React.FC<{ data: any }> = ({ data }) => {
    const searchIndex = data.siteSearchIndex.index;
    const spec = JSON.parse(data.spec.internal.content);

    return (
        <div className="spec-index">
            <DamlHubHelmet
                title={`${siteName} API Reference`}
                meta={[{ name: 'site', content: 'damlhub' }]}
            />
            <DocsNav searchIndex={searchIndex} />
            <RedocStandalone
                spec={spec}
                options={{
                    menuToggle: true,
                    disableSearch: true,
                    hideDownloadButton: true,
                    nativeScrollbars: true,
                    hideLoading: true,
                    hideHostname: false,
                    // expandResponses: 'all',
                    sortEnumValuesAlphabetically: true,
                    sortOperationsAlphabetically: true,
                    hideSingleRequestSampleTab: false,
                    hideSchemaTitles: false,
                    simpleOneOfTypeLabel: true,
                    showObjectSchemaExamples: true,
                    requiredPropsFirst: true,
                    sortPropsAlphabetically: false,
                    hideRequestPayloadSample: false,
                    showWebhookVerb: true,
                    generatedPayloadSamplesMaxDepth: 10,

                    theme: {
                        spacing: {
                            sectionVertical: 10,
                        },

                        colors: {
                            primary: {
                                main: colors.blue_60,
                            },
                            text: {
                                primary: text_color,
                            },
                            http: {
                                get: colors.green_20,
                                post: colors.blue_40,
                                put: '#95507c', // default, change later
                                options: '#947014', // default, change later
                                patch: '#bf581d', // default, change later
                                delete: colors.red_40,
                                basic: colors.grey_60,
                                link: colors.aqua_30,
                                head: colors.magenta_40,
                            },
                        },

                        typography: {
                            fontFamily: 'Lato, sans-serif',
                            fontWeightBold: '500',
                            headings: {
                                fontFamily: 'Lato, sans-serif',
                            },
                            code: {
                                fontFamily: 'Source Code Pro, monospace',
                            },
                        },
                        sidebar: {
                            width: '30%',
                            textColor: text_color,
                            backgroundColor: colors.grey_00,
                        },
                    },
                }}
            />
        </div>
    );
};

export default ApiIndex;
